.btn {
    background-color: $color-1;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 50px;

    font-size: 16px;
    color: #EDEDED;
    letter-spacing: 1.6px;
    transition: all 0.2s ease-in;

    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-2;
        color: #EDEDED;
    }
}
