// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
//@import 'utilities/wrapper';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



a.btn-link {
    width: 165px;
    height: 50px;
    background: $color-1;
    border-radius: 10px;
    transition: 0.3s;
    h5 {    
        font-weight: normal;
        font-size: 16px;
        color: #EDEDED;
        letter-spacing: 1.6px;
        transition: 0.3s;
    }
    &:hover {
        background: $color-2;
        transition: 0.3s;
        h5 {
            color: $color-white;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1024px) {
        h5 {
            font-size: 16px;
        }
        @media screen and (max-width: $size-xs-max) {
            width: 200px;
        }
    }
}


#section-loi25 {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 101;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 70px;
        left: initial;
        right: 10px;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 59%;
            padding: 0 5%;
            width: 100%;
            z-index: $z-index-slide-caption;
            h2 {
                font-weight: bold;
                font-size: $font-size-40;
                color: $color-white;
                padding-bottom: 10px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-white;
                line-height: 1.3;
                margin-bottom: 25px;
            }
            .section-title {
                padding-bottom: 42px;
                .title {
                    h4 {
                        font-weight: 600;
                        font-size: $font-size-18;
                        color: $color-white;
                        text-transform: uppercase;
                    }
                }
            }
            @media screen and (max-width: 1350px){
                top: 55%;
                @media screen and (max-width: 1024px) {
                    top: 50%;
                    h2 {
                        font-size: 1.7rem;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        top: 44%;
                        .section-title {
                            padding-bottom: 25px;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            top: 55%;
                            h2 {
                                font-size: 2rem;
                            }
                            p {
                                br {
                                    display: none;
                                }
                            }
                            a.btn-link {
                                width: 160px;
                            }
                            @media screen and (max-width: 375px) {
                                top: 52%;
                                @media screen and (max-width: 360px) {
                                    top: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 90px 5% 20px 5%;
    .text-side {
        background: #ED1C2410;
        width: 45%;
        padding: 50px 40px;
        margin-right: 15px;
        h4 {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-40;
            padding-bottom: 30px;
            color: $color-3;
        }
        p {
            font-size: $font-size-18;
            font-weight: 500;
        }
    }
    .image-side {
        width: 55%;
        background: url("../images/accueil_bloc_01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1625px) {
        .text-side {
            h4 {
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1460px) {
            .text-side {
                h4 {
                    font-size: 1.7rem;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                .image-side {
                    width: 75%;
                    margin: 0 auto 45px auto;
                }
                .text-side {
                    width: 75%;
                    margin: 0 auto;
                    h4 {
                        font-size: 2rem;
                        br {
                            display: block;
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .image-side, .text-side {
                        width: 100%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .image-side {
                            background: url("../images/accueil_bloc_01_m.jpg") no-repeat;
                            background-size: cover;
                            background-position: center;
                        }
                        .text-side {
                            padding: 65px 20px;
                            h4 {
                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title-images {
    padding: 75px 0 135px 0;
    margin: 0 5%;
    .title {
        border-top: 1px solid #707070;
        padding: 25px 0 0 0;
        h3 {
            font-family: $font-family-1;
            font-weight: 500;
            font-size: $font-size-50;
            color: $color-3;
        }
    }
    .section-images {
        padding-top: 75px;
        .image {
            h5 {
                font-weight: 600;
                font-size: $font-size-18;
                color: $color-3;
                text-transform: uppercase;
                padding-top: 22px;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        padding: 75px 0 100px 0;
        .title {
            h3 {
                font-size: 2.2rem;
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .title {
                h3 {
                    font-size: 2rem;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .section-images {
                    flex-direction: column;
                    align-items: initial;
                    .image.mx5 {
                        margin: 25px 0;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .title {
                        h3 {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

#section-title-services {
    padding: 75px 5%;
    background: #ED1C2407;
    h3 {
        font-weight: 600;
        font-size: $font-size-60;
        color: $color-3;
        text-transform: uppercase;
        padding-bottom: 80px;
    }
    .bloc {
        background: $color-2;
        width: 310px;
        height: 400px;
        .icon {
            padding-bottom: 60px;
        }
        .text {
            h4 {
                font-weight: 500;
                font-size: $font-size-20;
                color: $color-3;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 1650px) {
        .bloc {
            height: 350px;
        }
        @media screen and (max-width: 1450px) {
            .bloc {
                height: 310px;
                .icon {
                    padding-bottom: 20px;
                }
            }
            @media screen and (max-width: 1200px) {
                .bloc {
                    width: 300px;
                    height: 250px;
                    .icon {
                        width: 40%;
                    }
                }   
                @media screen and (max-width: 1024px) {
                    .section-services {
                        flex-wrap: wrap;
                        justify-content: center;
                        .bloc {
                            width: 32%;
                            height: 310px;
                            margin: 5px;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        h3 {
                            font-size: 2.5rem;
                            padding-bottom: 50px;
                        }
                        .section-services {
                            .bloc {
                                width: 48%;
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            h3 {
                                text-align: center;
                            }
                            .section-services {
                                .bloc {
                                    width: 100%;
                                    height: 300px;
                                    margin: 0 0 15px 0;
                                    .icon {
                                        width: 40%;
                                        display: flex;
                                        justify-content: center;
                                    }
                                    .text {
                                        h4 {
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text {
    margin: 25px 5% 100px 5%;
    background: #EDEDED;
    padding: 100px 5%;
    .left-side {
        width: 55%;
        h2 {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-40;
            color: $color-3;
        }
    }
    .right-side {
        width: 50%;
        p {
            font-weight: 500;
            font-size: $font-size-18;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .left-side {
            width: 100%;
            margin-bottom: 25px;
        }
        .right-side {
            width: 100%;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 75px 5%;
            margin: 75px 5%;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-text-image-full {
    padding: 100px 0;
    position: relative;
    .text-side {
        width: 50%;
        background: #EDEDED;
        margin-right: 15px;
        padding: 50px 35px 50px 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 35px;
        }
    }
    .image-side {
        width: 50%;
        background: url("../images/services_bloc_01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        padding: 75px 5%;
        .image-side {
            width: 75%;
            margin: auto;
        }
        .text-side {
            width: 75%;
            padding: 75px 35px;
            margin: 15px auto 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .text-side {
                    padding: 50px 25px;
                }
                .image-side {
                    background: url("../images/services_bloc_01_m.jpg") no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}

#section-image-text {
    padding: 0 5% 100px 5%;
    position: relative;
    .image-side {
        width: 50%;
        background: url("../images/services_bloc_02.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    .text-side {
        width: 50%;
        background: #EDEDED;
        margin-left: 15px;
        padding: 50px 50px 50px 65px;
        h4 {
            font-weight: 600;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 25px;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding: 0 5% 75px 5%;
        .image-side {
            width: 75%;
            margin: auto;
        }
        .text-side {
            width: 75%;
            padding: 75px 35px;
            margin: 15px auto 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .text-side {
                    padding: 50px 25px;
                }
                .image-side {
                    background: url("../images/services_bloc_02_m.jpg") no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}

#section-background {
    background: #ED1C2410;
    padding: 50px 5%;
    .image-side {
        width: 55%;
    }
    .text-side {
        width: 45%;
        margin-left: 50px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            padding-bottom: 30px;
        }
        a.btn-link {
            width: 170px;
            margin-top: 75px;
        }
    }
    @media screen and (max-width: 1550px) {
        .text-side {
            p {
                font-size: $font-size-18;
            }
            a.btn-link {
                margin-top: 1.25rem;
            }
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .image-side {
                width: 75%;
            }
            .text-side {
                width: 75%;
                margin: 50px 0 0 0;
                a.btn-link {
                    margin-top: 50px;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
            }
        }
    }
}

#section-text-image.services {
    padding: 90px 5% 75px 5%;
    .text-side {
        width: 50%;
        background: #EDEDED;
        h4 {
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            padding-bottom: 25px;
        }
        p {
            font-size: $font-size-20;
            font-weight: normal;
        }
        a.btn-link {
            width: 120px;
            margin-top: 50px;
        }
    }
    .image-side {
        width: 50%;
        background: url("../images/services_bloc_04.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1200px) {
        .text-side {
            width: 75%;
        }
        .image-side {
            width: 75%;
            margin: 0 auto 15px auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 90px 5% 0px 5%;
                .image-side {
                    background: url("../images/services_bloc_04_m.jpg") no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
}

/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-info-emploi {
    padding: 50px 0;
    border-bottom: 1px solid #2B2B2B;
    margin: 0 5%;
    .left-side {
        width: 50%;
        margin-right: 50px;
        h3 {
            font-weight: 500;
            font-size: $font-size-34;
            color: $color-black;
            text-transform: initial;
            line-height: 1.4;
        }
    }
    .right-side {
        width: 50%;
    }
    @media screen and (max-width: 1585px){
        .left-side {
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1200px){
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
            }
            .right-side {
                width: 100%;
            }  
        }
    }
}

#section-emploi {
    padding: 75px 5% 0 5%;
    div.section-wrapper {
        display: inline-grid;
        margin: auto;
        width: 90%;
        max-width: 1600px;;
        justify-items: stretch;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: 1fr;
        }
    }
    div.left-side, div.right-side {
        width: 50%;
        @media screen and (max-width: $size-xs-max){
          width: 100%;
        }
    }

    section.emplois-liste {
        display: flex;
        flex-wrap: wrap;
        div.item-poste {
            display: inline-block;
            margin-bottom: 20px;
            background-color: #EDEDED;
            transition: .5s;
            width: 49%;

            div.title {
                background-color: $color-3;
                padding: 30px 7%;
                h4 {
                    color: #EDF1FB;
                    font-size: 20px;
                    font-weight: bold;
                    text-transform: initial;
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.soustitre {
                padding: 30px 7%;
                .icon {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: #2B2B2B;
                    margin-right: 50px;
                    padding-bottom: 10px;
                    .fa {
                        font-size: 20px;
                        margin-right: 10px;
                        color: #2B2B2B;
                    }
                    @media screen and (max-width: 1420px){
                        margin-right: 25px;
                        @media screen and (max-width: 1270px){
                            margin-right: 0;
                            width: 100%;
                            margin-bottom: 15px;
                            @media screen and (max-width: 1110px){
                                margin-right: 25px;
                                width: initial;
                                margin-bottom: 0;
                                @media screen and (max-width: $size-xs-max){
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 30px 5%;
                }
            }
            div.content-full {
                padding: 0 7% 30px 7%;
                h4, h3, h2 {
                    font-size: 18px;
                    color: #2B2B2B;
                    padding: 15px 0;
                    font-weight: bold;
                }
                p, li {
                    color: #2B2B2B;
                    font-weight: normal;
                    font-size: 16px;
                    padding-left: 25px;
                    line-height: 2;
                    @media screen and (max-width: 1420px){
                        padding-left: 0;
                    }
                }
                .btn-wrapper {
                    max-width: 145px;
                    margin-top: 50px;
                    .btn {
                        background-color: $color-1;
                        border: none;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 135px;
                        height: 50px;

                        font-weight: normal;
                        font-size: 16px;
                        letter-spacing: 1.6px;
                        color: #EDEDED;
                        transition: 0.3s;
                        &:hover {
                            background: $color-2;
                            transition: 0.3s;
                        }
                    }
                }
                @media screen and (max-width: 1325px){
                    padding: 0 5% 30px 5%;
                }
            }
            &:nth-child(odd) {
                margin-right: 20px;
                @media screen and (max-width: 1110px){
                    margin-right: 0;
                }
            }
            @media screen and (max-width: 1110px){
                width: 100%;
                margin: 0 0 15px 0;
            }
        }
    }
}

#section-images {
    padding: 75px 5%;
   
}

section.section-form-emploi {
    padding: 60px 10% 100px 10%;
    margin-bottom: 75px;
    background-color: #ED1C2410;
    h3 {
        font-weight: bold;
        color: #3D4251;
        font-size: $font-size-30;
        padding-bottom: 40px;
        @media screen and (max-width: 360px) {
            font-size: 1.4rem;
        }
    }
    .form-group {
        margin-bottom: -10px;
    }
    input {
        height: 95px;
        background: $color-white;
        border: 1px solid #010206;
    }
    textarea#message {
        border: 1px solid #010206;
        background: $color-white;
    }
    input::placeholder, textarea::placeholder {
        font-weight: normal !important;
        font-size: $font-size-18 !important;
        color: #909090 !important;
    }
    textarea::placeholder {
        padding: 35px 5px !important;
    }
    form label.filedoc {
        background: $color-1;
        height: 50px;
        width: 175px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: normal;
        font-size: 16px;
        letter-spacing: 1.6px;
        color: #EDEDED;
        transition: 0.3s;
        
        &:hover {
            background: $color-2;
            transition: 0.3s;
        }
    }
    .btn {
        background: $color-1;
        height: 50px;
        width: 120px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: normal;
        font-size: 16px;
        letter-spacing: 1.6px;
        color: #EDEDED;
        transition: 0.3s;
        
        &:hover {
            background: $color-2;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .pl0 , .pr0, {
            padding: 0!important;
        }
        form label.filedoc {
            width: 230px;
            margin-right: 0;
        }
        form .buttons {
            justify-content: center;
            margin: 0 0 50px 0;
        }
        .btn-submit {
            margin-top: 15px;
            .btn.submit-emploi {
                width: 230px;
            }
        }
    }
    @media screen and (max-width: 1325px){
        padding: 60px 8% 25px 8%;
        @media screen and (max-width: $size-sm-max){
            padding: 60px 5% 25px 5%;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-info {
    padding: 50px 5%;
    border-bottom: 1px solid #DA556099;
    .bloc {
        width: 15%;
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            color: $color-3;
            padding-bottom: 28px;
        }
        p, a {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-3;
        }
        &.first {
            width: 37%;
        }
    }
    @media screen and (max-width: 1650px){
        .bloc {
            width: initial;
        }
        @media screen and (max-width: 1200px){
            flex-direction: column;
            .bloc {
                width: 100%;
                h3 {
                    padding-bottom: 15px;
                }
                &.mr5 {
                    margin: 0 0 35px 0;
                }
                &.first {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-xs-max){
                padding: 50px 5%;
            }
        }
    }
}

#section-form-heures {
    padding: 75px 5%;
    .section-form {
        width: 60%;
        margin-right: 5%;
    }
    .section-heures {
        width: 40%;
        .jours-heures {
            h4, h5 {
                font-weight: bold;
                font-size: $font-size-20;
                color: $color-3;
                line-height: 3;
            }
            h5 {
                font-weight: normal;
                text-align: right;
            }
            .jour {
                width: 39%;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        flex-direction: column-reverse;
        .section-heures {
            width: 100%;
            margin: 0 0 75px 0;
            .jours-heures .jour {
                width: 27%;
            }
        }
        .section-form {
            width: 100%;
            margin-right: 0;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 25px 5% 75px 5%;
            .section-heures {
                .jours-heures .jour {
                    width: 50%;
                }
            }
            .section-form {
                .col-sm-6.pr0, .col-sm-6.pl0, .col-sm-3 {
                    padding: 0;
                }
            }
        }
    }
}

#section-map {
    padding: 50px 5% 7px 5%;
}







/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
  

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
    

}
